import React from 'react';
import NavigationItem from './NavigationItem/NavigationItem';
import {List} from "@mui/material";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  list: {
    margin: "0",
    padding: "0",
    listStyle: "none",
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    height: "100%",
    '@media screen and (max-width: 720px)': {
      flexDirection: "column",
      height: "50%",
      margin: "auto"
    }
  }
}));

const NavigationItems = ({onHandlerNav}) => {
  const {classes} = useStyles();

  return (
    <List className={classes.list}>
      <NavigationItem to="/" exact onHandlerNav={onHandlerNav}>Главная</NavigationItem>
      <NavigationItem to="/products" onHandlerNav={onHandlerNav}>Продукция</NavigationItem>
      <NavigationItem to="/contacts" onHandlerNav={onHandlerNav}>Контакты</NavigationItem>
      <NavigationItem to="/about" onHandlerNav={onHandlerNav}>О нас</NavigationItem>
    </List>
  );
};

export default NavigationItems;
import React from 'react';
import {Box, Container, Grid, Link, Paper} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import Logo from "../Logo/Logo";

import instagram from '../../assets/insIcon.png';

const useStyles = makeStyles()(() => ({
  footer: {
    minHeight: "70px",
    background: "#1976D2",
    padding: "33.5px 0",
    color: "white",
    width: "100%",
    boxShadow: "0 -10px 10px 5px rgba(0, 0, 0, 0.5)",
    zIndex: "10"
  },
  iconImg: {
    width: "32px",
    height: "32px"
  },
  grid: {
    justifyContent: "space-between",
    alignItems: "center",
    '@media screen and (max-width: 720px)': {
      flexDirection: "column"
    }
  },
  item: {
    '@media screen and (max-width: 720px)': {
      marginBottom: "10px"
    }
  },
  gridLogo: {
    width: "17%",
    '@media screen and (max-width: 720px)': {
      display: "none"
    }
  },
  link: {
    textDecoration: "none",
    fontStyle: "italic",
    color: "gold"
  }
}));

const Footer = () => {
  const {classes} = useStyles();

  return (
    <Paper component="footer" square className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container className={classes.grid}>
          <Grid item className={classes.gridLogo}>
            <Logo/>
          </Grid>
          <Grid item className={classes.item}>
            <Box textAlign="center">
              <Link href="https://www.instagram.com/akkuu.kg/" target="_blank">
                <img src={instagram} alt="Instagram" className={classes.iconImg}/>
              </Link>
            </Box>
          </Grid>
          <Grid item>
            <Link
              href="mailto:oao-akkyy@mail.ru"
              target="_blank"
              className={classes.link}
              marginLeft="5px"
            >
              oao-akkyy@mail.ru
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default Footer;
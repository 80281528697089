import React from 'react';
import {Box, Typography} from "@mui/material";
import {Carousel} from "react-responsive-carousel";

const MainPageCarousel = ({images, classDescription, classImgBlock, show}) => (
  <Carousel infiniteLoop showThumbs={false} showStatus={false} transitionTime={1500}>
    {images.length !== 0 &&
      images.map((obj, i) => ((
        <Box key={i} className={classImgBlock} sx={{animation: `${show} 2s forwards`}}>
          <img src={obj.image}
               style={{
                 width: "1155px",
                 position: "absolute",
                 left: "50%",
                 top: "50%",
                 transform: "translateY(-50%) translateX(-50%)"
               }}
               alt=""
          />
          <Typography className={classDescription} variant="span" >
            {obj.description}
          </Typography>
        </Box>
      )))
    }
  </Carousel>
);

export default MainPageCarousel;
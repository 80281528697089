import React from 'react';
import {Grid, Typography} from "@mui/material";
import ProductsCarousel from "../ProductsCarousel/ProductsCarousel";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  product: {
    maxWidth: "860px",
    flexWrap: "nowrap",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "15px",
    margin: "0 auto 30px",
    border: "3px solid gold",
    borderRadius: "20px",
    opacity: "0",
    background: "#e3e3e3",
    '@media screen and (max-width: 640px)': {
      flexDirection: "column",

    }
  },
  imgBlock: {
    width: "40%",
    height: "auto",
    '@media screen and (max-width: 640px)': {
      width: "70%"
    },
    '@media screen and (max-width: 450px)': {
      width: "100%"
    }
  },
  contentBlock: {
    width: "60%",
    marginLeft: "10%",
    '@media screen and (max-width: 640px)': {
      marginLeft: "0"
    },
    '@media screen and (max-width: 450px)': {
      width: "max-content",
      margin: " 0 auto"
    }
  },
  title: {
    fontSize: "28px",
    marginBottom: "10px",
    '&:first-letter': {
      textTransform: "capitalize "
    },
    '@media screen and (max-width: 450px)': {
      fontSize: "24px",
      width: "200px"
    }
  }
}));

const Product = ({product, showAnimation}) => {
  const {classes} = useStyles();

  return (
    <Grid
      container
      className={classes.product}
      sx={{animation: `${showAnimation} 1s forwards`, animationDelay: "0.3s"}}
    >
      <Grid item className={classes.imgBlock}>
        <ProductsCarousel images={product.img}/>
      </Grid>
      <Grid item className={classes.contentBlock}>
        <Typography variant="h4" className={classes.title}>
          {product.title}
        </Typography>
        <Grid container flexWrap="nowrap">
          {product.categories &&
            <Grid item>
              <Typography variant="h5" fontSize="20px" fontWeight="700" paddingRight="20px">
                Категории:
              </Typography>
              {product.categories.map(name => (
                <Typography key={name} borderBottom="2px solid #5c8ffc" >
                  {name}
                </Typography>
              ))}
            </Grid>
          }
          {product.grams &&
            <Grid item>
              <Typography variant="h5" fontSize="20px" fontWeight="700">
                Вес:
              </Typography>
              {product.grams.map(gram => (
                <Typography
                  key={gram}
                  borderBottom="2px solid #5c8ffc"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography variant="span" >{gram}</Typography>
                  <Typography variant="span" marginLeft="5px">гр.</Typography>
                </Typography>
              ))}
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Product;
import React from 'react';
import AppToolbar from "../AppToolbar/AppToolbar";
import Footer from "../../Footer/Footer";
import {Box} from "@mui/material";

const Layout = (props) => {
  return (
    <>
      <AppToolbar/>
      <Box component="main" flex="1 1 auto">{props.children}</Box>
      <Footer/>
    </>
  );
};

export default Layout;
import React, {useState} from 'react';
import {Box, Container, FormControl, keyframes, MenuItem, Select} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import Contact from "../../components/Contact/Contact";

const show = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const useStyles = makeStyles()(() => ({
  select: {
    boxShadow: "0 1px 5px 5px rgba(0, 0, 0, 0.5)",
    marginTop: "20px",
    background: "#e3e3e3"
  }
}));

const Contacts = () => {
  const {classes} = useStyles();

  const [contacts] = useState([
    {
      division: "Генеральный директор",
      name: "Малабаева Нургуль Маликовна",
      phones: {
        o: "500 407 407",
        beeline: "772 326 045",
        megaCom: "555 326 045",
        whatsapp: "772 326 045",
        mail: "nurgulmalikovna@gmail.com"
      }
    },
    {
      division: "Исполнительный директор",
      name: "Асанбеков Назабек Ормонбекович",
      phones: {
        o: "500 919 738",
        beeline: "777 919 738",
        megaCom: "558 919 738",
        whatsapp: "777 919 738",
        mail: "oao-akkyy@mail.ru"
      }
    },
    {
      division: "Бухгалтерия",
      name: "Кыдыралиева Максат Ишеналиевна",
      phones: {
        o: "500 548 548",
        beeline: "772 548 548",
        whatsapp: "772 548 548",
        mail: "oao-akkyy79@mail.ru"
      }
    },
    {
      division: "Приемная",
      phones: {
        o: "505 663 641",
        tel: "313 460 227",
        whatsapp: "505 663 641",
        mail: "oao-akkyy@mail.ru"
      }
    },
    {
      division: "Коммерческий отдел",
      phones: {
        o: "701 031 966",
        beeline: "772 070 749",
        megaCom: "554 031 966",
        mail: "market.akkuu@bk.ru"
      }
    },
    {
      division: "Отдел реализации г.Бишкек",
      phones: {
        tel: "312 51 04 95",
        mail: "oao-akkyyo@mail.ru",
      }
    }
  ]);

  const [currentContact, setCurrentContact] = useState(null);

  const selectDivision = e => {
    const value = e.target.value;

    const userSelect = contacts.filter(contact => contact.division === value);
    if (userSelect.length !== 0) {
      setCurrentContact(userSelect[0]);
    }
  };

  return (
    <Container>
      <Box maxWidth="720px" marginX="auto">
        <FormControl fullWidth sx={{animation: `${show} 2s forwards`}}>
          <Select onChange={selectDivision} defaultValue="default" className={classes.select}>
            <MenuItem value="default" disabled>Выберите из списка для связи</MenuItem>
            {
              contacts ? contacts.map(contact => (
                <MenuItem key={contact.division} value={contact.division} sx={{animation: `${show} 2s forwards`}}>{contact.division}</MenuItem>
              )) : null}
          </Select>
        </FormControl>
      </Box>
      {
        currentContact ? <Contact contact={currentContact} show={show}/> : null
      }
    </Container>
  );
};

export default Contacts;
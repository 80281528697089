import React, {useState} from 'react';
import {Container, keyframes} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import MainPageCarousel from "../../components/MainPageCarousel/MainPageCarousel";

import chicken from '../../assets/chicken.jpg';
import corm from '../../assets/corm_dlya_kyp.jpg';
import eggs from '../../assets/eggs_main_pic.jpg';
import automaticEggs from '../../assets/mainPicAutomatic.jpg';
import automaticEggsBack from '../../assets/mainPicAutomaticBack.jpg';
import processingMachine from '../../assets/processingMachine.jpg';

const show = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const useStyles = makeStyles()(() => ({
  main: {
    height: "auto",
    padding: "0",
    margin: "auto",
    '@media screen and (max-width: 720px)': {
      marginTop: "-9px"
    }
  },
  imgBlock: {
    position: "relative",
    width: "100%",
    height: "80vh",
    overflow: "hidden",
    border: "3px solid #1976D2",
    opacity: "0",
    '@media screen and (max-width: 720px)': {
      borderRadius: "0",
    }
  },
  description: {
    fontSize: "36px",
    fontWeight: "bold",
    color: "yellow",
    position: "absolute",
    width: "70%",
    top: "40%",
    left: "50%",
    transform: "translateX(-50%)",
    '@media screen and (max-width: 720px)': {
      fontSize: "30px",
      width: "70%",
      top: "30%"
    },
    '@media screen and (max-width: 380px)': {
      fontSize: "20px",
      width: "80%",
      top: "40%"
    }
  }
}));

const STATICIMAGES = [
  {
    image: eggs,
    description: "ОАО «Ак-Куу» (Сокулукская птицефабрика) - " +
      "крупнейшее птицеводческое предприятие Кыргызской " +
      "Республики по производству яйца"
  },
  {image: automaticEggs},
  {image: automaticEggsBack},
  {image: chicken},
  {image: corm},
  {image: processingMachine}
];

const MainPage = () => {
  const {classes} = useStyles();

  const [images] = useState(STATICIMAGES);

  return (
    <Container className={classes.main}>
      <MainPageCarousel
        images={images}
        classImgBlock={classes.imgBlock}
        classDescription={classes.description}
        show={show}
      />
    </Container>
  );
};

export default MainPage;
import React from 'react';
import {NavLink} from 'react-router-dom';
import {ListItem} from "@mui/material";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  listItem: {
    margin: "0",
    padding: "0",
    height: "100%",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    '@media screen and (max-width: 720px)': {
      marginBottom: "20px",
      textAlign: "center"
    }
  },
  link: {
    fontSize: "18px",
    color: "white",
    height: "100%",
    padding: "25px 10px",
    textDecoration: "none",
    width: "100%",
    display: "block",
    boxSizing: "border-box",
    '&:hover, &:active, &.active': {
      background:
        "linear-gradient(180deg, " +
        "rgba(0,90,180,1) 0%, " +
        "rgba(14,104,193,1) 47%, " +
        "rgba(25,118,210,1) 100%)"
    },
    '@media screen and (max-width: 720px)': {
      marginBottom: "20px",
      paddingTop: "25px",
      fontSize: "22px"
    }
  }
}));

const NavigationItem = ({to, exact, children, onHandlerNav}) => {
  const {classes} = useStyles();

  return (
    <ListItem className={classes.listItem}>
      <NavLink to={to} exact={exact} onClick={onHandlerNav} className={classes.link}>{children}</NavLink>
    </ListItem>
  );
};

export default NavigationItem;
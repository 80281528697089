import React from 'react';
import {Grid, Link, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';

const useStyles = makeStyles()(() => ({
  contact: {
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "720px",
    margin: "70px auto 40px",
    padding: "15px 20px",
    border: "3px solid gold",
    borderRadius: "20px",
    boxShadow: "0 1px 5px 5px rgba(0, 0, 0, 0.5)",
    background: "#e3e3e3",
    '@media screen and (max-width: 650px)': {
      display: "block"
    },
    '@media screen and (max-width: 360px)': {
      padding: "10px 2px"
    }
  },
  contentBlock: {
    '@media screen and (max-width: 650px)': {
      '&:first-of-type': {
        marginBottom: "20px"
      },
    },
  },
  phone: {
    display: "flex",
    justifyContent: "space-between",
    minWidth: "200px",
    borderBottom: "2px solid #5c8ffc",
    margin:"10px 0",
  },
  link: {
    textDecoration: "none"
  }
}));

const Contact = ({contact, show}) => {
  const {classes} = useStyles();

  return (
    <Grid container className={classes.contact} sx={{animation: `${show} 2s forwards`}}>
      <Grid item className={classes.contentBlock}>
        <Typography variant="h6" fontWeight="400">
          {contact.division}
        </Typography>
        <Typography variant="h6" sx={{fontStyle: "italic"}}>
          {contact.name}
        </Typography>
      </Grid>
      <Grid item className={classes.contentBlock}>
        {Object.keys(contact.phones).map((phone, i) => (
          <Typography className={classes.phone} key={i}>
            <Typography variant="span" textTransform="capitalize" marginRight="10px" fontWeight="700">
              {phone === 'o' ? phone + '!' : phone}:
            </Typography>
            {
              phone === 'whatsapp' ? (
                <Link
                  className={classes.link}
                  target="_blank"
                  href={`https://api.whatsapp.com/send/?phone=+996${contact.phones[phone]}&text=Здравствуйте`}
                >
                  <WhatsAppIcon fontSize="small" sx={{marginBottom: "-5px", marginRight: "5px"}}/>
                  0{contact.phones[phone]}
                </Link>
              ) : (
                <Link
                  className={classes.link}
                  href={phone === 'mail' ? `mailto:${contact.phones[phone]}`
                    : `tel:+996${contact.phones[phone]}`
                  }
                >
                  {
                    phone === 'mail' ? <MailIcon fontSize="small" sx={{marginBottom: "-5px", marginRight: "5px"}}/>
                      : <PhoneIcon fontSize="small" sx={{marginBottom: "-5px", marginRight: "5px"}}/>
                  }
                  {phone !== 'mail' && '0'}{contact.phones[phone]}
                </Link>
              )
            }
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
};

export default Contact;
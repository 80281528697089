import React from 'react';
import {Grid, Typography} from "@mui/material";
import AboutCarousel from "../AboutCarousel/AboutCarousel";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()(() => ({
    historyBlock: {
        flexDirection: "column",
        alignItems: "center",
        flexWrap: "nowrap",
        '@media screen and (max-width: 960px)': {},
    },
    historyContent: {
        width: "80%",
        padding: "30px",
        fontSize: "22px",
        '@media screen and (max-width: 960px)': {
            width: "95%",
            fontSize: "19px"
        },
        '@media screen and (max-width: 560px)': {
            width: "95%",
            fontSize: "16px"
        }
    },
    title: {
        textAlign: "center",
        width: "max-content",
        fontSize: "36px",
        fontStyle: "italic",
        textTransform: "uppercase",
        border: "3px solid black",
        borderBottom: "none",
        margin: "0 auto",
        padding: "0 5px",
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
        '@media screen and (max-width: 560px)': {
            fontSize: "26px"
        }
    }
}));

const History = ({historyImages}) => {
    const {classes} = useStyles();

    return (
      <Grid container className={classes.historyBlock}>
          <Grid item className={classes.historyContent}>
              <AboutCarousel images={historyImages} title={classes.title}/>
          </Grid>
          <Grid item className={classes.historyContent}>
              <Typography variant="p">
                  ОАО «Ак-Куу» (Сокулукская птицефабрика) была основана в 1966 году как государственное предприятие,
                  являлась одной из 12 птице предприятий в Кыргызской Советской Республике. Расположена в селе Сокулук,
                  в 30 км. на запад от столицы Кыргызстана – г.Бишкек. Как и все другие подобные предприятия, хозяйство
                  развивалось, увеличивая из года год свое производство. Основной целью фабрики являлось
                  обеспечение населения свежим яйцом. Побочным продуктом являлось мясо кур и полуфабрикаты, в том числе
                  колбасы.
                  В 1993 году была преобразовано в ОАО «Ак-Куу» согласно Постановления Сокулукской райгосадминистрации
                  за
                  № 238 от 27 апреля 1993 года. В результате развала Советского Союза и разрыва всех договоров на
                  поставку кормовых добавок, а также нехватки оборотного капитала, производство в ОАО «Ак-Куу» в конце
                  1996
                  года было приостановлено. В 1999 году фабрика возобновила свою деятельность.
              </Typography>
          </Grid>
      </Grid>
    );
};

export default History;
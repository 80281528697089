import React, {useState} from 'react';
import {AppBar, IconButton, Menu, MenuItem, Toolbar, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import Logo from "../../Logo/Logo";
import NavigationItems from "../../NavigationItems/NavigationItems";

import MenuIcon from '@mui/icons-material/Menu';

const useStyles = makeStyles()(theme => ({
  staticToolbar: {
    marginBottom: theme.spacing(1.2),
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    boxShadow: "0 10px 10px 5px rgba(0, 0, 0, 0.5)",
  },
  navigation: {
    '@media screen and (max-width: 720px)': {
      display: "none",
      height: 0
    }
  },
  navigationMenu: {
    display: "none",
    '@media screen and (max-width: 720px)': {
      display: "flex"
    }
  },
  bgMenu: {
    '& div': {
      borderRadius: "20px"
    },
    '& ul': {
      background: "#1976D2"
    }
  }
}));


const AppToolbar = () => {
  const {classes} = useStyles();
  const [action, setAction] = useState(null);

  const handleMenu = e => {
    if (action) {
      setAction(null);
    } else {
      setAction(e.currentTarget);
    }
  };

  return (
    <>
     <AppBar position="fixed" className={classes.header}>
       <Toolbar>
         <Typography  variant="h6">
           <Logo/>
         </Typography>
       </Toolbar>
       <Toolbar className={classes.navigation}>
         <NavigationItems/>
       </Toolbar>
       <Toolbar className={classes.navigationMenu}>
         <IconButton onClick={handleMenu}>
           <MenuIcon/>
         </IconButton>
         {action ?
           <Menu
             className={classes.bgMenu}
             anchorEl={action}
             anchorOrigin={{
               vertical: "top",
               horizontal: "right",
             }}
             keepMounted
             transformOrigin={{
               vertical: "top",
               horizontal: "right",
             }}
             open={Boolean(action)}
             onClose={handleMenu}
           >
             <MenuItem onClick={handleMenu}>
               <NavigationItems/>
             </MenuItem>
           </Menu> : null
         }
       </Toolbar>
     </AppBar>
     <Toolbar className={classes.staticToolbar}/>
    </>
  );
};

export default AppToolbar;
import React, {useEffect, useMemo, useState} from 'react';
import {Container, keyframes, Typography} from "@mui/material";
import 'animate.css/animate.min.css';

import brownEggs from '../../assets/brown_eggs.JPG';
import whiteEggs from '../../assets/white_eggs.JPG';
import nariste30 from '../../assets/nariste30.JPG';
import nariste30brown from '../../assets/nariste30brown.jpg';
import nariste10 from '../../assets/nariste10.JPG';
import nariste10brown from '../../assets/nariste10brown.jpg';
import keremet10white from '../../assets/keremet10white.jpg';
import keremet10 from '../../assets/keremet10.JPG';
import meat from '../../assets/meat.JPG';
import sausage from '../../assets/sausage.png';
import sausageMoscow from '../../assets/moscow_sausage.JPG';
import sausageServalat from '../../assets/servalatSausage.jpg';
import chickenSausages from '../../assets/chicken_sausages.JPG';
import milkSausages from '../../assets/milk_sausages.JPG';
import chickenBigSausages from '../../assets/chicken_big_sausages.JPG';
import dietarySausage from '../../assets/dietarySausage.JPG';
import doctorSausage from '../../assets/doctorSausage.JPG';
import amateurSausage from '../../assets/amateur.JPG';
import manty from '../../assets/manty.JPG';
import pelmeni from '../../assets/pelmeni.JPG';
import fricadelki from '../../assets/frycadelki.JPG';
import poroshok from '../../assets/poroshok.jpg';
import miniPoroshok from '../../assets/poroshokMini.jpg';
import udobrenie from '../../assets/udobrenie.jpg';
import ProductsBlock from "../../components/ProductsBlock/ProductsBlock";

const STATICPRODUCTS = [
  {
    title: "кремовое яйцо",
    img: [whiteEggs],
    categories: ['высшая', 'C0', 'C1', 'C2'],
    grams: ['75+', '65-75', '55-64', '45-55'],
    left: true
  },
  {
    title: "коричневое яйцо",
    img: [brownEggs],
    categories: ['высшая', 'C0', 'C1', 'C2'],
    grams: ['75+', '65-75', '55-64', '45-55'],
    left: false
  },
  {
    title: "яйцо упокованное 10 штук Наристе",
    img: [nariste10, nariste10brown],
    left: true
  },
  {
    title: "яйцо упокованное 10 штук Керемет",
    img: [keremet10, keremet10white],
    left: false
  },
  {
    title: "яйцо упокованное 30 штук Наристе",
    img: [nariste30, nariste30brown],
    left: true
  },
  {
    title: "яичный порошок",
    img: [poroshok, miniPoroshok],
    left: false
  },
  {
    title: "органическое удобрение из куриного помёта",
    img: [udobrenie],
    left: true
  },
  {
    title: "Куриное мясо",
    img: [meat],
    left: false
  },
  {
    title: "полуфабрикаты",
    img: [pelmeni, manty, fricadelki],
    left: true
  },
  {
    title: "копчёные колбасы",
    img: [
      sausage, sausageMoscow, sausageServalat
    ],
    left: false
  },
  {
    title: "варёные колбасы",
    img: [
      chickenSausages, chickenBigSausages,
      milkSausages,
      doctorSausage, dietarySausage,
      amateurSausage
    ],
    left: true
  }
];

const show = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const screenWidth = window.screen.width;

const Products = () => {
  const [products, setProducts] = useState(null);
  const [page, setPage] = useState(1);

  useMemo(() => {
    const halfProducts = STATICPRODUCTS.slice(0, 5);
    setProducts(halfProducts);
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, [products]);

  const onChange = (e, value) => {
    if (value === 1) {
      const halfProducts = STATICPRODUCTS.slice(0, 5);
      setProducts(halfProducts);
    } else if (value === 2) {
      const halfProducts = STATICPRODUCTS.slice(5);
      setProducts(halfProducts);
    }
    setPage(value);
  };

  return (
    <Container>
      <Typography
        variant="h2"
        fontWeight="700"
        textAlign="center"
        marginY="30px"
        sx={{
          fontSize: {xs: "30px", md: "44px"},
          opacity: "0" ,
          animation: `${show} 1s forwards`,
          animationDelay: "0.3s"
        }}
      >
        Наша продукция
      </Typography>
      {products ? (
        <ProductsBlock
          products={products}
          page={page}
          show={show}
          screenWidth={screenWidth}
          onChange={onChange}
        />
      ) : <Typography textAlign="center" variant="h1" color="red">Что-то пошло не так!</Typography>
      }
    </Container>
  );
};

export default Products;
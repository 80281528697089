import React from 'react';
import {Link} from "react-router-dom";
import './Logo.css';

import logo from '../../assets/logo.png';

const Logo = () => (
  <Link key="Logo" to="/">
    <img src={logo} className="Logo" alt="Logo"/>
  </Link>
);

export default Logo;
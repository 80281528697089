import React from 'react';
import {Box} from "@mui/material";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ProductsCarousel = ({images}) => {
  return (
    <Box padding="20px">
      <Carousel infiniteLoop showThumbs={false} autoPlay interval={4000} transitionTime={1300}>
        {images &&
          images.map(img => ((
            <Box key={img} display="table-cell" sx={{verticalAlign: "middle", height: "200px"}}>
              <img src={img} alt=""/>
            </Box>
          )))
        }
      </Carousel>
    </Box>
  );
};

export default ProductsCarousel;
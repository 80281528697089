import React from 'react';
import {Carousel} from "react-responsive-carousel";
import {Box, Typography} from "@mui/material";

const AboutCarousel = ({images, title}) => {
  return (
    <Box>
      <Carousel infiniteLoop showThumbs={false} showStatus={false} autoPlay interval={4000} transitionTime={1300}>
        {images &&
          images.map(img => ((
            <Box key={img.image} display="table-cell" sx={{verticalAlign: "middle", height: "300px"}}>
              {img.type ?
                img.type === 'old' ?
                  <Typography
                    variant="h5"
                    className={title}
                  >
                    тогда
                  </Typography>
                  :
                  <Typography
                    variant="h5"
                    className={title}
                  >
                    сейчас
                  </Typography> : <div style={{height: "48px"}}></div>
              }
              <img src={img.image} style={{border: "3px solid black", borderRadius: "20px"}} alt=""/>
            </Box>
          )))
        }
      </Carousel>
    </Box>
  );
};

export default AboutCarousel;
import React from 'react';
import {Box, Pagination, Typography} from "@mui/material";
import {AnimationOnScroll} from "react-animation-on-scroll";
import Product from "../Product/Product";
import "animate.css/animate.min.css";

const ProductsBlock = (props) => (
  <>
    <Box>
      <Typography
        fontSize="18px"
        fontWeight="500"
        textAlign="end"
        maxWidth="860px"
        margin="0 auto 30px"
        paddingRight="10px"
        sx={{ opacity: "0", animation: `${props.show} 1s forwards`, animationDelay: "0.3s"}}
      >
        Страница: {props.page}
      </Typography>
      {props.screenWidth > 720 ? props.products.map(product => (
        <AnimationOnScroll key={product.title} animateIn="animate__rubberBand" animateOnce>
          <Product product={product} showAnimation={props.show}/>
        </AnimationOnScroll>
      )): props.products.map(product => (
        <Product product={product} showAnimation={props.show}/>
      ))}
    </Box>
    <Pagination
      count={2}
      color="primary"
      onChange={props.onChange}
      size="large"
      sx={{marginBottom: "60px", width: "max-content" , marginX: "auto"}}
    />
  </>
);

export default ProductsBlock;
import React, {useState} from 'react';
import {Box, Container, keyframes, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import History from "../../components/History/History";

import yearSelection from '../../assets/year-selection.jpg';
import oldMachineCorm from '../../assets/oldMachineCorm.jpg';
import newMachineCorm from '../../assets/newMachineCorm.jpg';
import oldEggPicker from '../../assets/oldEggPicker.jpg';
import newEggPicker from '../../assets/mainPicAutomatic.jpg';
import oldOffice from '../../assets/oldOffice.jpg';
import newOffice from '../../assets/newOffice.jpg';
import oldZoneDoor from '../../assets/oldZoneDoor.jpg';
import newZoneDoor from '../../assets/newZoneDoor.jpg';
import oldProcessingMachine from '../../assets/oldProcessingMachine.jpg';
import newProcessingMachine from '../../assets/processingMachine.jpg';

const show = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const useStyles = makeStyles()(() => ({
  title: {
    fontSize: "44px",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "50px",
    '@media screen and (max-width: 560px)': {
      fontSize: "30px",
      marginBottom: "10px"
    }
  },
  videoBlock: {
    width: "90%",
    margin: "0 auto 100px"
  },
  video: {
    width: "100%",
    height: "592px",
    '@media screen and (max-width: 960px)': {
      height: "469px"
    }
  },
  historyBlock: {
    alignItems: "center",
    flexWrap: "nowrap",
    '@media screen and (max-width: 960px)': {
      flexDirection: "column"
    },
  },
  historyContent: {
    width: "50%",
    padding: "30px",
    '@media screen and (max-width: 960px)': {
      width: "80%",
    },
    '@media screen and (max-width: 560px)': {
      width: "95%"
    }
  },
}));

const About = () => {
  const {classes} = useStyles();

  const [historyImages] = useState([
    {
      type: "old",
      image: oldEggPicker
    },
    {
      type: "new",
      image: newEggPicker
    },
    {
      type: "old",
      image: oldMachineCorm
    },
    {
      type: "new",
      image: newMachineCorm
    },
    {
      type: "old",
      image: oldZoneDoor
    },
    {
      type: "new",
      image: newZoneDoor
    },
    {
      type: "old",
      image: oldOffice
    },
    {
      type: "new",
      image: newOffice
    },
    {
      type: "old",
      image: oldProcessingMachine
    },
    {
      type: "new",
      image: newProcessingMachine
    },
    {
      type: null,
      image: yearSelection
    }
  ]);

  return (
    <Container sx={{opacity: "0", animation: `${show} 2s forwards`, animationDelay: "0.4s"}}>
      <Typography variant="h2" className={classes.title}>О нашей компании</Typography>
      <Box className={classes.videoBlock}>
        <iframe
          className={classes.video}
          style={{borderRadius: "20px"}}
          src="https://www.youtube.com/embed/w_uo_dszC-c"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Box>
      <Typography variant="h2" className={classes.title}>История</Typography>
      <History
        historyImages={historyImages}
      />
    </Container>
  );
};

export default About;
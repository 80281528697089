import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import App from './App';
import axios from "axios";
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

const getVisitors = async () => {
  try {
    const {data} = await axios('https://other-content-educational-default-rtdb.europe-west1.firebasedatabase.app/akkuu_visitors.json');

    if (data) {
      const today = new Date().getUTCDate();

      if (today !== data.date) {
        data.date = today;
        data.today = 0;
      }

      data.general++;
      data.today++;

      console.log(data.today);
      console.log(data.general)

      await axios.patch('https://other-content-educational-default-rtdb.europe-west1.firebasedatabase.app/akkuu_visitors.json', data);
    }
  } catch (e) {
    console.log(e)
  }
};

getVisitors().catch(e => console.log(e));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {Redirect, Route, Switch} from "react-router-dom";
import Layout from "./components/UI/Layout/Layout";
import MainPage from "./containers/MainPage/MainPage";
import Products from "./containers/Products/Products";
import Contacts from "./containers/Contacts/Contacts";
import About from "./containers/About/About";

const App = () => (
  <Layout>
    <Switch>
      <Route path="/" exact component={MainPage}/>
      <Route path="/products" component={Products}/>
      <Route path="/contacts" component={Contacts}/>
      <Route path="/about" component={About}/>
      <Redirect to="/"/>
    </Switch>
  </Layout>
);

export default App;
